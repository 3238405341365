import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import productReducer from './slices/product';
import elementReducer from './slices/element';
import magictypeReducer from './slices/magictype';
import tagReducer from './slices/tag';
import cardReducer from './slices/card';
import rarityReducer from './slices/rarity';
import spellReducer from './slices/spell';
import characterReducer from './slices/character';
import appointReducer from './slices/appoint';
import todayShadowReducer from './slices/todayShadow';
import slotReducer from './slices/slot';
import creviewReducer from './slices/creview';
import cProviderReducer from './slices/clinicProvider';
import DressCode from './slices/DressCode';
import RegistrationForm from './slices/RegistrationForm';
import CheckInfo from './slices/CheckInfo';

const rootPersistConfig = {
  key: 'root',
  storage,
  // keyPrefix: 'redux-',
  // whitelist: [],
};


const rootReducer = combineReducers({
  element:elementReducer,
  magictype:magictypeReducer,
  tag:tagReducer,
  card:cardReducer,
  rarity:rarityReducer,
  spell:spellReducer,
  character:characterReducer,
  product: productReducer,
  appoint: appointReducer,
  todayShadow: todayShadowReducer,
  slot: slotReducer,
  creview: creviewReducer,
  cProvider: cProviderReducer,
  dresscode: DressCode,
  registrationForm: RegistrationForm,
  checkInfo: CheckInfo,
});

export { rootPersistConfig, rootReducer };
